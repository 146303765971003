import React from "react"
import Sidebar from '../../components/sidebar'
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import lgHausys2 from "../../assets/img/lghausys2.jpg"

const hiMacsStructuraPage = () => (
  <Layout>
    <SEO title="chargeur sans fil intégré" />
 
    <div class="grid grid-cols-1 md:grid-cols-4 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
       <div class="grid grid-cols-1 gap-2 md:col-span-3 md:my-5 mx-3"> {/** first element */}
           
            <h1 class="text-2xl text-center font-bold mb-2 divider">Qu’est-ce que HI-MACS Structura®?</h1>
            <p class="text-justify mt-3">
            <iframe width="368" height="207" src="https://www.youtube.com/embed/m93gfxTvWEs" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> </p>
            <p>
            Vous avez toujours rêvé de proposer une surface texturée, une surface reflétant des trésors de savoir-faire ? Aujourd’hui, grâce à la nouvelle technologie HI-MACS Structura®, HI-MACS® peut reproduire quasiment n’importe quel modèle – et ce avec une extrême précision.<br />

            <span class="font-bold">Quelque soit l’endroit où vous voulez donner du relief à votre design : en intérieur, en extérieur, sur des murs et bien plus.</span><br />
            Choisissez parmi les dix surfaces standards disponibles ou concevez votre propre produit HI-MACS Structura® pour satisfaire les besoins du design de votre projet.
            </p>
            <h1 class="text-2xl text-center font-bold mb-4 mt-5 divider">MODÈLES STANDARDS HI-MACS STRUCTURA®</h1>
            <p>
            Ces dix modèles standards de HI-MACS Structura® forment une base idéale pour des conceptions à l’infini – minimaliste et graphique comme Classic, aux lignes dynamiques avec Air Stream ou, à l’image de Zen, une surface rappelant des galets incrustés. HI-MACS Structura® offre une dimension de plus au matériau classique en plaques.
            </p>
       </div>

       <div>
         <Sidebar />
       </div>
    </div>
  </Layout>
  )
 
  export default hiMacsStructuraPage
